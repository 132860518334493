import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
  useState,
} from "react";
import { FetchResult, useMutation, useQuery } from "@apollo/client";
import { isEmpty } from "lodash";

import {
  ADD_DISCOUNT_CODE_MUTATION,
  ADD_CART_ITEM_MUTATION,
  CREATE_CART_MUTATION,
  GET_CART,
  UPDATE_CART_ITEMS_MUTATION,
  REMOVE_CART_ITEM_MUTATION,
} from "./Cart.queries";

import { getLocaleFromPath } from "../../../plugins/gatsby-plugin-outer-i18n";
import * as Analytics from "../../utils/analytics";

import { useUserContext } from "../User/User.context";
import {
  buildCartItem,
  formatAnalyticsObject,
  persistCartState,
} from "./helpers";
import {
  SpecialCartActions,
  SPECIAL_COUPONS,
  CART_EXPIRATION_TIME,
} from "../../../configs/cart";

import {
  CartItemProps,
  CartContextTypeProps,
  CreateCartResponseProps,
  CartResponseProps,
  CartError,
} from "./cart";

import { CartReducer, loadCartFromLocalStorage } from "./Cart.reducer"; // Assuming you have a reducer and initial state defined

const CartContext = createContext<CartContextTypeProps | undefined>(undefined);

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  /**
   * ***********************************
   * STATES
   * ***********************************
   */
  const [state, dispatch] = useReducer(CartReducer, loadCartFromLocalStorage());

  /**
   * ***********************************
   * CONTEXTS
   * ***********************************
   */
  const {
    state: { user, loading: userLoading },
  } = useUserContext();

  /**
   * ***********************************
   * QUERIES
   * ***********************************
   */

  const { data, loading, refetch } = useQuery(GET_CART, {
    context: {
      locale: user.locale.code,
    },
    variables: { id: state.cartId },
    skip: userLoading || !state.cartId,
  });

  /**
   * ***********************************
   * MUTATIONS
   * ***********************************
   */

  const [createCart] = useMutation(CREATE_CART_MUTATION);
  const [updateCartMutation] = useMutation(UPDATE_CART_ITEMS_MUTATION);
  const [addCartItemMutation] = useMutation(ADD_CART_ITEM_MUTATION);
  const [removeCartItemMutation] = useMutation(REMOVE_CART_ITEM_MUTATION);
  const [promoCodeMutation] = useMutation(ADD_DISCOUNT_CODE_MUTATION);

  /**
   * ***********************************
   * ACTIONS
   * ***********************************
   */
  const createNewCart = async () => {
    await createCart({
      context: {
        locale: user.locale.code,
      },
    })
      .then((res: FetchResult<CreateCartResponseProps>) => {
        if (res.data && res.data.cartCreate.cart) {
          const {
            cartCreate: { cart },
          } = res.data;
          dispatch({ type: "CART_RECEIVED", payload: cart });
        } else if (res.data && res.data.cartCreate.userErrors.length > 0) {
          dispatch({
            type: "CART_ERROR",
            payload: { errors: res.data.cartCreate.userErrors },
          });
        }
      })
      .catch(err => {
        setCartLoading(false);
        dispatch({ type: "CART_ERROR", payload: { errors: err } });
      });
  };

  const addItems = async (items: CartItemProps[]) => {
    dispatch({ type: "UPDATING_CART", payload: {} });

    const itemsArray = items.map(item => ({
      quantity: item.quantity,
      merchandiseId: item.merchandise.id!,
      attributes: item.attributes,
    }));

    await addCartItemMutation({
      context: {
        locale: user.locale.code,
      },
      variables: {
        cartId: state.cartId,
        lines: itemsArray,
      },
    })
      .then(
        (
          res: FetchResult<{
            cartLinesAdd: {
              userErrors: CartError[];
              cart: CartResponseProps;
            };
          }>
        ) => {
          if (res.data && res.data.cartLinesAdd.cart) {
            const {
              cartLinesAdd: { cart },
            } = res.data;
            dispatch({ type: "CART_RECEIVED", payload: cart });
            items.forEach(item => {
              Analytics.track("Product Added", formatAnalyticsObject(item));
            });
          } else if (res.data && res.data.cartLinesAdd.userErrors.length > 0) {
            const {
              cartLinesAdd: { userErrors },
            } = res.data;

            setCartLoading(false);

            if (userErrors.some(error => error.code === "INVALID")) {
              const productTitles = items
                .map(item => item.merchandise.product.title)
                .join(", ");

              dispatch({
                type: "CART_ERROR",
                payload: {
                  errors: [
                    ...userErrors,
                    {
                      code: "PRODUCT_ISSUE",
                      message: `There is an issue with ${productTitles}.`,
                    },
                  ],
                },
              });
            } else {
              dispatch({
                type: "CART_ERROR",
                payload: { errors: userErrors },
              });
            }
          }
        }
      )
      .catch(err => {
        setCartLoading(false);
        dispatch({ type: "CART_ERROR", payload: { errors: err } });
      });
  };

  const increaseItem = async (item: CartItemProps) => {
    const itemId = {
      id: item.id!,
      quantity: item.quantity + 1,
    };

    return await updateItems([itemId]).then(() => {
      Analytics.track("Product Added", formatAnalyticsObject(item));
    });
  };

  const decreaseItem = async (item: CartItemProps) => {
    const itemId = {
      id: item.id!,
      quantity: item.quantity - 1,
    };
    return await updateItems([itemId]).then(() => {
      Analytics.track("Product Removed", formatAnalyticsObject(item));
    });
  };

  const updateItems = async (items: { id: string; quantity: number }[]) => {
    dispatch({ type: "UPDATING_CART", payload: {} });

    await updateCartMutation({
      context: {
        locale: user.locale.code,
      },
      variables: {
        cartId: state.cartId,
        lines: items,
      },
    })
      .then(
        (
          res: FetchResult<{
            cartLinesUpdate: {
              userErrors: CartError[];
              cart: CartResponseProps;
            };
          }>
        ) => {
          if (res.data && res.data.cartLinesUpdate.cart) {
            const {
              cartLinesUpdate: { cart },
            } = res.data;
            dispatch({ type: "CART_RECEIVED", payload: cart });
          } else if (
            res.data &&
            res.data.cartLinesUpdate.userErrors.length > 0
          ) {
            setCartLoading(false);
            dispatch({
              type: "CART_ERROR",
              payload: { errors: res.data.cartLinesUpdate.userErrors },
            });
          }
        }
      )
      .catch(err => {
        setCartLoading(false);
        dispatch({ type: "CART_ERROR", payload: { errors: err } });
      });
  };

  const removeItems = async (items: CartItemProps[]) => {
    dispatch({ type: "UPDATING_CART", payload: {} });

    const itemIds = items.map(item => item.id);

    await removeCartItemMutation({
      context: {
        locale: user.locale.code,
      },
      variables: {
        cartId: state.cartId,
        lineIds: itemIds,
      },
    })
      .then(
        (
          res: FetchResult<{
            cartLinesRemove: {
              userErrors: CartError[];
              cart: CartResponseProps;
            };
          }>
        ) => {
          if (res.data && res.data.cartLinesRemove.cart) {
            const {
              cartLinesRemove: { cart },
            } = res.data;
            dispatch({ type: "CART_RECEIVED", payload: cart });
            items.forEach(item => {
              Analytics.track("Product Removed", formatAnalyticsObject(item));
            });
          } else if (
            res.data &&
            res.data.cartLinesRemove.userErrors.length > 0
          ) {
            setCartLoading(false);
            dispatch({
              type: "CART_ERROR",
              payload: { errors: res.data.cartLinesRemove.userErrors },
            });
          }
        }
      )
      .catch(err => {
        setCartLoading(false);
        dispatch({ type: "CART_ERROR", payload: { errors: err } });
      });
  };

  const removeAll = async () => {
    dispatch({ type: "UPDATING_CART", payload: {} });

    if (!state.cart) return;
    const items = state.cart.lines.edges.map(edge => edge?.node);
    removeItems(items);
  };

  const onCheckout = async () => {
    if (!state.cart) return;
    const items = state.cart.lines.edges.map(edge => edge?.node);
    const products = items.map(el => formatAnalyticsObject(el));

    Analytics.track("Initiate Checkout", {
      products,
      ...(state.promoCode.length > 1 && { promoCode: state.promoCode }),
    });

    Analytics.track("Checkout Started");
    dispatch({ type: "CHECKING_OUT", payload: {} });
    window.location = state.checkoutUrl as Location;
  };

  const applyPromo = async (code: string) => {
    dispatch({ type: "UPDATING_CART", payload: {} });
    const formattedCode = code.toUpperCase();

    Analytics.track("Coupon Entered", { coupon_id: formattedCode });

    // LOGIC TO APPLY SPECIAL CODE IF EXISTS IN CODE
    for (const specialCode of SPECIAL_COUPONS) {
      const formattedSpecialCode = specialCode.code.toUpperCase();
      if (formattedSpecialCode === formattedCode) {
        const cartObj = buildCartItem({
          merchandise: { id: specialCode.variantId },
          quantity: 1,
          attributes: [
            {
              key: `_variantId`,
              value: "0",
            },
          ],
        });
        await addItems([cartObj]);
      }
    }

    // LOGIC TO APPLY SPECIAL ACTION IS EXISTS IN CODE
    const specialAction = SpecialCartActions[code];
    if (specialAction) {
      const items = state.cart?.lines.edges.map(edge => edge?.node);
      const item = specialAction(items);
      if (item) {
        const cartObj = buildCartItem({
          merchandise: { id: item },
          quantity: 1,
          attributes: [
            {
              key: `_variantId`,
              value: "0",
            },
          ],
        });
        await addItems([cartObj]);
      }
    }

    // Check for applied promo codes

    const existingPromoCodes: string[] =
      state.cart?.discountCodes
        .filter(code => code.applicable)
        .map(code => code.code) ?? [];

    const codesToApply = [...existingPromoCodes, formattedCode];

    return await promoCodeMutation({
      context: {
        locale: user.locale.code,
      },
      variables: {
        cartId: state.cartId,
        discountCodes: codesToApply,
      },
    })
      .then(
        (
          res: FetchResult<{
            cartDiscountCodesUpdate: {
              cart: CartResponseProps;
              userErrors: CartError[];
            };
          }>
        ) => {
          if (res.data && res.data.cartDiscountCodesUpdate.cart) {
            const {
              cartDiscountCodesUpdate: { cart, userErrors },
            } = res.data;

            const isCodeApplied = cart?.discountCodes?.some(
              discountCode =>
                formattedCode === discountCode.code.toUpperCase() &&
                discountCode.applicable
            );

            if (isCodeApplied) {
              dispatch({
                type: "APPLIED_PROMO",
                payload: { code: formattedCode, errors: userErrors },
              });
              Analytics.track("Coupon Applied", { coupon_id: formattedCode });
            } else {
              Analytics.track("Coupon Denied", { coupon_id: formattedCode });

              dispatch({
                type: "PROMO_ERROR",
                payload: {
                  errors:
                    userErrors.length > 0
                      ? userErrors
                      : [
                          {
                            code: "DISCOUNT_NOT_FOUND",
                          },
                        ],
                },
              });
            }
            setLoadingPromo(false);
            dispatch({ type: "CART_RECEIVED", payload: cart });
          } else if (
            res.data &&
            res.data.cartDiscountCodesUpdate.userErrors.length > 0
          ) {
            setCartLoading(false);
            dispatch({
              type: "CART_ERROR",
              payload: { errors: res.data.cartDiscountCodesUpdate.userErrors },
            });
          }
        }
      )
      .catch(err => {
        setCartLoading(false);
        dispatch({ type: "CART_ERROR", payload: { errors: err } });
      });
  };

  const setCartModalVisible = async cartModalVisible => {
    dispatch({
      type: "SET_CART_MODAL_VISIBLE",
      payload: { cartModalVisible },
    });
  };

  const setCartLoading = async loading => {
    dispatch({
      type: "SET_CART_LOADING",
      payload: { loading },
    });
  };

  const setClydeLoading = async clydeLoading => {
    dispatch({
      type: "SET_CLYDE_LOADING",
      payload: { clydeLoading },
    });
  };

  // TRACKER LOGIC

  const setInitPurchaseTrackerItems = async () => {
    await dispatch({
      type: "SET_PURCHASE_TRACKER_ITEMS",
      payload: null,
    });
  };

  const setPurchaseTrackerItems = async item => {
    await dispatch({
      type: "SET_PURCHASE_TRACKER_ITEMS",
      payload: item,
    });
  };

  const setDiscountSelected = async discountSelected => {
    dispatch({
      type: "SET_DISCOUNT_SELECTED",
      payload: { discountSelected },
    });
  };

  const toggle = () => {
    if (state.show === true) {
      dispatch({ type: "HIDE_CART", payload: {} });
    } else {
      Analytics.track("Cart Viewed");
      dispatch({ type: "SHOW_CART", payload: {} });
    }
  };

  const savePromo = async promoCode => {
    dispatch({ type: "SAVE_PROMO", payload: { promoCode } });
  };

  const retryPromo = async () => {
    dispatch({ type: "RETRY_PROMO", payload: {} });
  };

  const clearError = async () => {
    dispatch({ type: "CLEAR_ERROR", payload: {} });
  };

  /**
   * ***********************************
   * CHECK FOR EXISTING CART
   * ***********************************
   */

  useEffect(() => {
    if (userLoading) return;
    const pathLocale = getLocaleFromPath(window.location.pathname);

    if (
      isEmpty(state.cart) ||
      pathLocale !== user.locale.code ||
      (state.timestamp !== 0 && CART_EXPIRATION_TIME >= state.timestamp)
    ) {
      createNewCart();
      return;
    }

    if (state.synced === false && !loading && data) {
      if (!data.cart) {
        createNewCart();
      } else {
        dispatch({ type: "CART_RECEIVED", payload: data.cart });
      }
    }
  }, [data, state.cartId, userLoading]);

  /**
   * ***********************************
   * URL PROMO LOGIC
   * ***********************************
   */

  const [loadingPromo, setLoadingPromo] = useState(false);

  useEffect(() => {
    let myPromo = "";
    if (typeof window.URLSearchParams != "undefined") {
      const query = new window.URLSearchParams(window.location.search);
      myPromo = query.get("promo") || "";
      myPromo = myPromo.toUpperCase(); // Promos should always be uppercase
    }

    if (myPromo != "" && state.cartId) {
      Analytics.track("Promo Code Visit", { promo: myPromo });
      dispatch({ type: "SAVE_URL_PROMO", payload: { urlPromoCode: myPromo } });
    }
  }, [state.cartId]);

  /**
   * ***********************************
   * UPDATE CART ON FOCUS
   * ***********************************
   */

  useEffect(() => {
    if (!state.cartId) return;
    if (!state.synced) {
      refetch().then(() => {
        dispatch({ type: "SET_SYNCED", payload: { synced: true } });
      });
    } else {
      persistCartState(state);
    }
  }, [state.synced, state.show]);

  useEffect(() => {
    const setCartSync = () =>
      dispatch({ type: "SET_SYNCED", payload: { synced: false } });
    window.addEventListener("focus", setCartSync);

    return () => {
      window.removeEventListener("focus", setCartSync);
    };
  }, []);

  /**
   * ***********************************
   * APPLY URL PROMO CODE
   * ***********************************
   */

  useEffect(() => {
    const items = state.cart?.lines?.edges?.map(edge => edge?.node) || [];

    if (
      !loadingPromo &&
      items.length &&
      state.urlPromoCode &&
      state.urlPromoCode != "" &&
      !state.loading &&
      state.promoCode !== state.urlPromoCode
    ) {
      applyPromo(state.urlPromoCode);
      setLoadingPromo(true);
    }

    const areValidProducts = items.every(item => item.attributes!.length);
    if (!areValidProducts) {
      createNewCart();
    }
  }, [state.urlPromoCode, state.cart?.lines?.edges]);

  /**
   * ***********************************
   * HELPERS
   * ***********************************
   */

  const actions = {
    createNewCart,
    setCartModalVisible,
    setCartLoading,
    setClydeLoading,
    setInitPurchaseTrackerItems,
    setPurchaseTrackerItems,
    setDiscountSelected,
    toggle,
    removeItems,
    applyPromo,
    savePromo,
    retryPromo,
    removeAll,
    addItems,
    onCheckout,
    increaseItem,
    decreaseItem,
    clearError,
  };

  return (
    <CartContext.Provider value={{ state, actions }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
