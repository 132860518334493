module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://b219da83d4144550ac0fa244f1067f7b@sentry.io/1411290","environment":"draft","sampleRate":0.7,"tracesSampleRate":1,"enabled":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Outer | The perfect outdoor sofa is now within reach.","short_name":"Outer","description":"The perfect outdoor sofa is now within reach.","start_url":"/","background_color":"#0a4683","display":"standalone","icon":"static/icons/icon.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"f08581561de607b31630da25854a0ac2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-outer-segment/gatsby-browser.js'),
      options: {"plugins":[],"writeKey":"5lIqtqhw7jIAhrtUyx17Z4wepzZa49Cl","posthogKey":"phc_orFfqryR9IrlQNJLpKw9DSbwTRC41CruI3fVhXfezcP","trackPageDelay":50,"host":"https://evs.analytics.liveouter.com","delayLoad":false,"manualLoad":false,"environment":"production"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
