import { gql } from "@apollo/client";

const CartFragment = gql`
  fragment CartFragment on Cart {
    id
    checkoutUrl
    cost {
      subtotalAmount {
        amount
        currencyCode
      }
      totalAmount {
        amount
        currencyCode
      }
    }
    discountCodes {
      code
      applicable
    }
    discountAllocations {
      discountedAmount {
        amount
        currencyCode
      }
    }
    lines(first: 100) {
      edges {
        node {
          id
          quantity
          merchandise {
            ... on ProductVariant {
              id
              title
              image {
                url(transform: { maxWidth: 100, maxHeight: 100 })
              }
              price {
                amount
                currencyCode
              }
              product {
                title
                id
              }
            }
          }
          attributes {
            key
            value
          }
          discountAllocations {
            discountApplication {
              allocationMethod
              targetType
              targetSelection
            }
            discountedAmount {
              amount
            }
            ... on CartCodeDiscountAllocation {
              code
            }
          }
        }
      }
    }
  }
`;

export const CREATE_CART_MUTATION = gql`
  mutation cartCreate($input: CartInput) {
    cartCreate(input: $input) {
      cart {
        id
        checkoutUrl
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`;

/*
 * ***********************************
 * GET_CART INPUT EXAMPLE
 * ***********************************
 *
 *   {
 *      "id": "gid://shopify/Cart/Z2NwLXVzLWVhc3QxOjAxSk5HRkpaUUI2MDlKOUZOUDlNSzBXWEFZ?key=cf00f0a8d004f2d5cafd0db95304b0d4",
 *    }
 */

export const GET_CART = gql`
  query getCart($id: ID!) {
    cart(id: $id) {
      ...CartFragment
    }
  }
  ${CartFragment}
`;

/*
 * ***********************************
 * UPDATE_CART_ITEMS_MUTATION INPUT EXAMPLE
 * ***********************************
 *
 *   {
 *       "cartId": "gid://shopify/Cart/Z2NwLXVzLWVhc3QxOjAxSk5HRkpaUUI2MDlKOUZOUDlNSzBXWEFZ?key=cf00f0a8d004f2d5cafd0db95304b0d4",
 *       "lines":[
 *          {
 *            "quantity":0,
 *            "id":"gid://shopify/CartLine/b23064fa-346f-440d-82bf-847a193b3a46?cart=Z2NwLXVzLWVhc3QxOjAxSk5HRkpaUUI2MDlKOUZOUDlNSzBXWEFZ"
 *          }
 *        ]
      }
 */

export const UPDATE_CART_ITEMS_MUTATION = gql`
  mutation cartLinesUpdate($lines: [CartLineUpdateInput!]!, $cartId: ID!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...CartFragment
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${CartFragment}
`;

/*
 * ***********************************
 * ADD_CART_ITEM_MUTATION INPUT EXAMPLE
 * ***********************************
 *
 *   {
 *     "cartId": "gid://shopify/Cart/Z2NwLXVzLWVhc3QxOjAxSk5HRkpaUUI2MDlKOUZOUDlNSzBXWEFZ?key=cf00f0a8d004f2d5cafd0db95304b0d4",
 *     "lines":[
 *       {
 *         "merchandiseId": "gid://shopify/ProductVariant/41391403401391",
 *         "quantity":5,
 *       }
 *     ]
 *   }
 */

export const ADD_CART_ITEM_MUTATION = gql`
  mutation cartLinesAdd($lines: [CartLineInput!]!, $cartId: ID!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...CartFragment
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${CartFragment}
`;

/*
 * ***********************************
 * REMOVE_CART_ITEM_MUTATION INPUT EXAMPLE
 * ***********************************
 *
 *   {
 *     "cartId": "gid://shopify/Cart/Z2NwLXVzLWVhc3QxOjAxSk5HRkpaUUI2MDlKOUZOUDlNSzBXWEFZ?key=cf00f0a8d004f2d5cafd0db95304b0d4",
 *     "lineIds": [
 *       "gid://shopify/CartLine/f9da75fa-102d-4186-aeb2-6b19ba3c814b?cart=Z2NwLXVzLWVhc3QxOjAxSk5HRkpaUUI2MDlKOUZOUDlNSzBXWEFZ"
 *     ]
 *   }
 */

export const REMOVE_CART_ITEM_MUTATION = gql`
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...CartFragment
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${CartFragment}
`;

export const ADD_DISCOUNT_CODE_MUTATION = gql`
  mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]!) {
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
      cart {
        ...CartFragment
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${CartFragment}
`;

export const PRODUCT_DATA_QUERY = gql`
  query PRODUCT_DATA_QUERY($remoteId: String!, $locale: String!) {
    products(where: { _remoteId_contains: $remoteId }, locale: $locale) {
      title
      enable
      remoteId
      variantType
      currency {
        name
        code
        symbol
      }
      variants {
        enable
        includeInTracker
        id
        remoteId
        title
        price
        compareAtPrice
        photos {
          url
          height
          width
        }
        shippingSLA
      }
    }
  }
`;

export const PRODUCT_PAGE_DATA_QUERY = gql`
  query PRODUCT_PAGE_DATA_QUERY($productPageId: ID!) {
    productPage(id: $productPageId) {
      title
      enable
      productVariants {
        includeInTracker
        id
        remoteId
        productRemoteId
        title
        price
        compareAtPrice
        media {
          url
          height
          width
        }
        shippingSLA
      }
    }
  }
`;
