import { CartStateProps } from "./cart"; // Adjust the path as necessary
import { persistCartState } from "./helpers";

export const initialState: CartStateProps = {
  cartId: null,
  loading: true,
  synced: false,
  show: false,
  checkoutUrl: "",
  cartModalVisible: false,
  clydeLoading: true,
  purchaseTrackerItems: [],
  discountSelected: "",
  promoCode: "",
  urlPromoCode: "",
  errors: [],
  lines: [],
  cart: null,
  timestamp: 0,
};

export const loadCartFromLocalStorage = () => {
  if (typeof window !== "undefined" && window.localStorage) {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      const cart = JSON.parse(savedCart);
      return { ...initialState, ...cart };
    }
  }
  return initialState;
};

export const CartReducer = (
  state: CartStateProps,
  action: any
): CartStateProps => {
  switch (action.type) {
    case "CHECKING_OUT":
      return { ...state, loading: true };
    case "CART_RECEIVED":
      const lines =
        action.payload.lines?.edges.map(edge => ({
          cartLineId: edge.node.id!,
          merchandiseId: edge.node.merchandise.id!,
          quantity: edge.node.quantity,
        })) || [];

      return {
        ...state,
        loading: false,
        synced: true,
        cartId: action.payload.id,
        checkoutUrl: action.payload.checkoutUrl,
        cart: action.payload,
        lines,
      };
    case "APPLIED_PROMO":
      return {
        ...state,
        promoCode: action.payload.code,
        urlPromoCode: "",
        discountSelected: "",
        errors: action.payload.errors,
      };
    case "PROMO_ERROR":
      return {
        ...state,
        errors: action.payload.errors,
        promoCode: "",
        urlPromoCode: "",
      };
    case "UPDATING_CART":
      return { ...state, loading: true };
    case "SET_CART_MODAL_VISIBLE":
      return {
        ...state,
        cartModalVisible: action.payload.cartModalVisible,
      };
    case "SET_CART_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    case "SET_CLYDE_LOADING":
      return {
        ...state,
        clydeLoading: action.payload.clydeLoading,
      };
    case "SET_PURCHASE_TRACKER_ITEMS":
      const newItems = action.payload
        ? [
            ...(state.purchaseTrackerItems.filter(
              item => item.remoteId !== action.payload.remoteId
            ) || []),
            ...(action.payload.quantity > 0 ? [action.payload] : []),
          ]
        : [];
      return {
        ...state,
        purchaseTrackerItems: newItems,
      };
    case "SET_DISCOUNT_SELECTED":
      return {
        ...state,
        discountSelected: action.payload.discountSelected,
      };
    case "SET_SYNCED":
      return {
        ...state,
        synced: action.payload.synced,
      };
    case "HIDE_CART":
      return { ...state, show: false };
    case "SHOW_CART":
      return { ...state, show: true };
    case "SAVE_PROMO":
      return { ...state, promoCode: action.payload.promoCode };
    case "RETRY_PROMO":
      return { ...state, errors: [] };
    case "SAVE_URL_PROMO":
      return { ...state, urlPromoCode: action.payload.urlPromoCode };
    case "CART_ERROR":
      return { ...state, errors: action.payload.errors };
    case "CLEAR_ERROR":
      return { ...state, errors: [] };
    default:
      return state;
  }
};
