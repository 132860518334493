import { omit, sortBy } from "lodash";

import { decodeSku } from "../../utils/helpers";
import * as Store from "../../utils/localDb";

import { CartItemProps, CartStateProps } from "./cart";

export const findProductWarranty = (items, itemId) =>
  items
    .filter(item => item.merchandise.product.title.includes("Protection Plan"))
    .find(warranty =>
      warranty.attributes?.some(attr =>
        attr.key.includes(`ref-${decodeSku(itemId, true)}`)
      )
    );

export const persistCartState = (cartState: CartStateProps) => {
  if (typeof window !== "undefined" && window.localStorage) {
    let persistedCart = {
      cart: cartState.cart,
      cartId: cartState.cartId,
      show: cartState.show,
      timestamp: Math.round(new Date().getTime() / 1000),
    };
    Store.save("cart", persistedCart);
  }
};

export const sortItems = (items: CartItemProps[]): CartItemProps[] => {
  return sortBy(items, i => {
    if (
      i.attributes?.length &&
      i.attributes.some(attr => attr.key.includes("ref-"))
    ) {
      return i.attributes?.find(attr => attr.key.includes("ref-"))?.key;
    }

    let title = "";
    if (i.merchandise && i.merchandise.product) {
      title = `${i.merchandise.product.title} - ${i.merchandise.title}`;
    } else if (i.merchandise) {
      title = i.merchandise.title!;
    }
    return title;
  });
};

export const buildCartItem = ({
  merchandise,
  quantity,
  attributes,
}): CartItemProps => ({
  merchandise: {
    id: merchandise?.id || "",
    title: merchandise?.title || "",
    product: {
      id: merchandise?.product?.id || "",
      title: merchandise?.product?.title || "",
    },
    price: {
      currencyCode: merchandise?.price?.currencyCode || "USD",
      amount: merchandise?.price?.amount || 0,
    },
  },
  quantity: quantity || 1,
  attributes: attributes || [
    {
      key: `_variantId`,
      value: "0",
    },
  ],
});

export const formatAnalyticsObject = (item: CartItemProps) => {
  return {
    id: decodeSku(item.merchandise.id, true),
    product_id: decodeSku(item.merchandise.product.id, false),
    sku: item.merchandise.id,
    name: item.merchandise?.product.title,
    variant: item.merchandise.title,
    price: item.merchandise.price.amount,
    quantity: item.quantity,
  };
};

export const hasVariant = (
  items: CartItemProps[],
  variantId: string
): boolean => {
  if (items.find(i => i.merchandise.id === variantId)) {
    return true;
  } else {
    return false;
  }
};

export const encodeCustomAttributes = customAttributes =>
  Object.keys(customAttributes).reduce((acc, attr) => {
    // @ts-ignore
    acc.push({ key: attr, value: `${customAttributes[attr]}` });
    return acc;
  }, []);

export const decodeCustomAttributes = customAttributes =>
  customAttributes.reduce((acc, attr) => {
    return {
      ...acc,
      [attr["key"]]: attr["value"],
    };
  }, {});
